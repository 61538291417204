import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import "./art.css";

const projects = [
    "Inner Child",
    "A Series of Writings",
    "Memory Blending",
    "A Boy and His Bugs",
    "Placeholder 1",
    "Placeholder 2",
    "Placeholder 3",
    "Placeholder 4",
];

const images = {
    "Inner Child": [
        `${process.env.PUBLIC_URL}/McDowell_Ethan_01.JPG`,
    ],
    // Add image arrays for other projects if needed
};

const projectDetails = {
    "Inner Child": {
        year: "2023",
        medium: "paper-cut photographs on stretched canvas LED lightbox",
        size: "12 x 12 inches",
        type: "images",
    },
    "A Series of Writings": {
        year: "2023",
        medium: "Video performance",
        size: "4:30 mins",
        type: "video",
        videoSrc: "https://streamable.com/e/1i903k",
    },
    "Memory Blending": {
        year: "2024",
        medium: "Mixed media",
        size: "20 x 30 inches",
        type: "placeholder",
    },
    "A Boy and His Bugs": {
        year: "2024",
        medium: "Digital print",
        size: "18 x 24 inches",
        type: "placeholder",
    },
    "Placeholder 1": { type: "placeholder" },
    "Placeholder 2": { type: "placeholder" },
    "Placeholder 3": { type: "placeholder" },
    "Placeholder 4": { type: "placeholder" },
};

class Art extends Component {
    state = {
        selectedProject: null,
        loading: true,
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1500);

        const navBar = document.querySelector(".menu-bar");
        if (navBar) navBar.style.display = "none";
    }

    componentWillUnmount() {
        const navBar = document.querySelector(".menu-bar");
        if (navBar) navBar.style.display = "flex";
    }

    changeProject = (projectName) => {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ selectedProject: projectName, loading: false });
        }, 1000);
    };

    goBack = () => {
        this.setState({ selectedProject: null });
    };

    render() {
        const { selectedProject, loading } = this.state;

        return (
            <div className="art-container">
                <CSSTransition
                    in={loading}
                    timeout={500}
                    classNames="loading-screen"
                    unmountOnExit
                >
                    <div className="loading-screen">
                        <div className="loading-line"></div>
                    </div>
                </CSSTransition>

                {/* Homepage Table */}
                {!selectedProject && !loading && (
                    <div className="visa-table">
                        <h1 className="visa-title">VISA Honors Portfolio</h1>
                        <div className="visa-header">
                            <span className="visa-column selected">Title</span>
                            <span className="visa-column">Details</span>
                        </div>
                        <div className="visa-list">
                            {projects.map((project, index) => (
                                <div
                                    key={index}
                                    className="visa-row"
                                    onClick={() => this.changeProject(project)}
                                >
                                    <span className="visa-column">{project}</span>
                                    <span className="visa-column">→</span>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {/* Selected Project Page */}
                {selectedProject && !loading && (
                    <div className="project-detail">
                        <button className="back-button" onClick={this.goBack}>
                             go back to VISA Honors Portfolio
                        </button>
                        <div
                            className={`project-info ${
                                selectedProject === "Inner Child" ? "judd-style-layout" : ""
                            }`}
                        >
                            <div className="project-meta">
                                <h2>{selectedProject}</h2>
                                {projectDetails[selectedProject]?.year && (
                                    <p><strong>Year:</strong> {projectDetails[selectedProject].year}</p>
                                )}
                                {projectDetails[selectedProject]?.medium && (
                                    <p><strong>Medium:</strong> {projectDetails[selectedProject].medium}</p>
                                )}
                                {projectDetails[selectedProject]?.size && (
                                    <p><strong>Size:</strong> {projectDetails[selectedProject].size}</p>
                                )}
                            </div>

                            {projectDetails[selectedProject]?.type === "images" && (
                                <div className="project-gallery">
                                    {images[selectedProject].map((picture, index) => (
                                        <img
                                            src={picture}
                                            alt={selectedProject}
                                            className="detail-photo"
                                            key={index}
                                        />
                                    ))}
                                </div>
                            )}

                            {projectDetails[selectedProject]?.type === "video" && (
                                <div className="video-container">
                                    <iframe
                                        className="art-video"
                                        src={projectDetails[selectedProject].videoSrc}
                                        frameBorder="0"
                                        allowFullScreen
                                        title={selectedProject}
                                    ></iframe>
                                </div>
                            )}

                            {projectDetails[selectedProject]?.type === "placeholder" && (
                                <p className="coming-soon">More coming soon...</p>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default Art;